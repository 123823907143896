import React from "react";
import useSWR from "swr";
import {appUrls} from "../../util/api_constant";
import {post, postWithParams} from "../../services/BaseService";

const HomeReviewsSection = ({}:{}) => {
    const {data, error} = useSWR<{ page: number, pageTotal: number, data: any[] }>(`${appUrls.feedbacks}`, url => post(url, {}));

    return <div className="section-reviews">
      <div className="uk-container">
          <div className="section-title uk-text-center">
              <div className="uk-text-meta">Ils nous ont fait confiance</div>
              <div className="uk-h2">Nos clients et partenaires fidèles</div>
          </div>
          <div className="section-content">
              <div data-uk-slider="autoplay: true">
                  <div className="uk-position-relative" >
                      <ul className="uk-slider-items uk-grid uk-grid-medium uk-child-width-1-2@m">
                          {
                              (data && data.data)?
                                  data.data.map(d => (
                                      <li key={`feed_${d.id}`}>
                                          {
                                              (d.cover)?
                                                  <div className="review-item" title={d.company}>
                                                      <img src={d.cover} alt={d.company} />
                                                  </div>:
                                                  <div className="review-item">
                                                      <div className="review-item__box">
                                                          <div className="review-item__title">
                                                              {d.title}
                                                          </div>
                                                          <div className="review-item__text">
                                                              {d.content}
                                                          </div>
                                                          <div className="review-item__user">
                                                              <div className="review-item__avatar"><img
                                                                  src="/assets/img/review-avatar-1.png" alt="review-avatar" /></div>
                                                              <div className="review-item__info">
                                                                  <div className="review-item__name">{d.customer}</div>
                                                                  <div className="review-item__position">{d.company}</div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                          }
                                      </li>
                                  )): null
                          }
                      </ul>
                  </div>
                  <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin-large-top"></ul>
              </div>
          </div>
      </div>
  </div>
}

export default HomeReviewsSection;